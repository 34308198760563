import React, { useEffect } from 'react';
import Ajax from '../../common/ajax';
import Config from '../../config';

export default function MercadoPagoStatusV2({ checkout, publicKey = null }) {
  useEffect(() => {
    if (!document.getElementById('sdkmp')) {
      const script = document.createElement('script');
      script.id = 'sdkmp';
      script.src = 'https://sdk.mercadopago.com/js/v2';
      script.onload = () => fetchConfiguration();
      document.body.appendChild(script);
    } else {
      fetchConfiguration();
    }

    // Cleanup on unmount.
    return () => {
      if (window.statusScreenBrickController) {
        window.statusScreenBrickController.unmount();
      }
    };
  }, []);

  async function fetchConfiguration() {
    const render = (key) => {
      const mp = new MercadoPago(key);
      const bricksBuilder = mp.bricks();
      renderStatusScreenBrick(bricksBuilder);
    };

    if (publicKey) {
      render(publicKey);
    } else {
      const url = `${Config.apiHost}checkouts_mercadopago/config/`;
      Ajax.get(url).then((configuration) => {
        render(configuration.public_key);
      });
    }
  }

  function hideLoading() {
    const el = document.getElementById('loading-div');
    if (el) {
      el.classList.remove('be-loading-active');
    }
  }

  function showLoading() {
    const el = document.getElementById('loading-div');
    if (el) {
      el.classList.add('be-loading-active');
    }
  }

  async function renderStatusScreenBrick(bricksBuilder) {
    const settings = {
      initialization: {
        paymentId: checkout.payment_code,
      },
      callbacks: {
        onReady: () => hideLoading(),
        onError: (error) => {
          console.error(error);
        },
      },
    };
    window.statusScreenBrickController = await bricksBuilder.create(
      'statusScreen',
      'statusScreenBrick_container',
      settings,
    );
  }

  return (
    <>
      <div id="loading-div" className="be-loading be-loading-active" style={{ minHeight: '200px' }}>
        {/* Needs to use the pure loading div because MercadoPago React does not handle react state changes very well. */}
        <div className="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle fill="none" strokeWidth="4" strokeLinecap="round" cx="33" cy="33" r="30" className="circle" />
          </svg>
        </div>

        <div id="statusScreenBrick_container" />
      </div>
    </>
  );
}
