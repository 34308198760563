import React, { Component } from 'react';
import Ajax from '../../common/ajax';
import { AlertError } from '../../common/components/Alert';
import Button from '../../common/components/Button';
import Input from '../../common/components/form/Input';
import SearchSelect from '../../common/components/form/SearchSelect';
import Select from '../../common/components/form/Select';
import Textarea from '../../common/components/form/Textarea';
import Modal from '../../common/containers/Modal';
import { showDjangoValidationMessages } from '../../common/validation';
import Config from '../../config';
import { isSuperuser } from '../../context/global';

class VoucherCreationButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      plans: [],
      form: {},
      processing: false,
      codes: null,
      error: false,
      selectedLead: null,
      leadError: null,
      replyLanguage: 'Portuguese',
    };
    this.openModalCreate = this.openModalCreate.bind(this);
    this.closeModalCreate = this.closeModalCreate.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.selectLead = this.selectLead.bind(this);
    this.formRef = React.createRef();
    this.voucherUlRef = React.createRef();
  }

  openModalCreate() {
    this.setState((state) => ({
      ...state,
      processing: true,
      codes: null,
      messages: {},
      error: false,
      form: {},
      selectedLead: null,
      leadError: null,
    }));
    const plansUrl = `${Config.apiHost}plans/`;
    Ajax.get(plansUrl).done((plans) => {
      this.setState((state) => ({
        ...state,
        showModal: true,
        processing: false,
        plans,
        form: {
          ...state.form,
          plan_id: plans[0].id,
        },
      }));
    });
  }

  closeModalCreate() {
    if (this.state.codes) {
      // Clear the form and keep it open to ease voucher creation.
      this.openModalCreate();
    } else {
      this.setState((state) => ({
        ...state,
        showModal: false,
      }));
    }
  }

  onInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState((state) => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
      },
    }));
  }

  onSubmit(evt) {
    this.setState((state) => ({
      ...state,
      processing: true,
      leadError: null,
    }));
    evt.preventDefault();
    const url = `${Config.apiHost}vouchers/bulk_create/`;
    Ajax.post(url, this.state.form)
      .done((response) => {
        this.setState((state) => ({
          ...state,
          processing: false,
          error: false,
          codes: response.codes,
          messages: response.messages,
        }));
        if (this.state.form.quantity > 0) {
          this.copyToClipboard(300);
        }
      })
      .fail((jqXHR) => {
        showDjangoValidationMessages(jqXHR.responseJSON);
        this.setState((state) => ({
          ...state,
          processing: false,
          error: true,
        }));
      });

    if (this.state.selectedLead) {
      const urlDelete = `${Config.apiHost}mailing/unsubscribe_professor/?email=${this.state.selectedLead.email}&voucherAmount=${this.state.form.quantity}`;
      Ajax.delete_(urlDelete).fail((jqXHR) => {
        this.setState((state) => ({ ...state, leadError: jqXHR.responseText }));
      });
    }
  }

  copyToClipboard(delay = 100) {
    const { current: ul } = this.voucherUlRef;
    if (ul) {
      setTimeout(() => {
        const range = new Range();
        range.selectNode(ul);
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(range);
        // document.execCommand('copy');
      }, delay);
    }
  }

  selectLead(selected) {
    if (this.state.form.description) {
      this.setState((state) => ({
        ...state,
        selectedLead: selected,
        form: {
          ...state.form,
          quantity: selected.voucher_amount,
        },
      }));
    } else {
      this.setState((state) => ({
        ...state,
        selectedLead: selected,
        form: {
          ...state.form,
          description: `${selected.ies_initials} - ${selected.fullname} - ${selected.email}`,
          quantity: selected.voucher_amount,
        },
      }));
    }
  }

  getSelectedPlan() {
    const { plan_id } = this.state.form;
    const { plans } = this.state;
    if (plan_id && plans) {
      return plans.find((p) => p.id == plan_id);
    }
    return {};
  }

  render() {
    if (!isSuperuser()) {
      return null;
    }

    const { first_name } = this.state.selectedLead || { first_name: 'XXXXX' };
    const isFemale = first_name.endsWith('a') || first_name.endsWith('e');

    return (
      <>
        <Button
          type="default"
          onClick={this.openModalCreate}
          style={{ marginRight: '5px' }}
        >
          Criar
        </Button>

        <Modal
          title={<>Criar vouchers {}</>}
          show={this.state.showModal}
          onCancel={this.closeModalCreate}
        >
          {this.state.leadError && (
            <AlertError>
              Erro ao remover professor: {this.state.leadError}
            </AlertError>
          )}

          {this.state.error && (
            <AlertError>Error ao criar os vouchers.</AlertError>
          )}

          {this.state.codes && (
            <>
              <Select
                name="replyLanguage"
                value={this.state.replyLanguage}
                options={Object.keys(this.state.messages).map((key) => ({
                  label: key,
                  value: key,
                }))}
                onChange={(e) => {
                  e.persist();
                  this.setState((state) => ({
                    ...state,
                    replyLanguage: e.target.value,
                  }));
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.messages[this.state.replyLanguage].replace(
                    '[first_name]',
                    `prof.${isFemale ? 'ª' : ''} ${first_name}`
                  ),
                }}
                ref={this.voucherUlRef}
              ></div>
            </>
          )}

          {!this.state.codes && (
            <form ref={this.formRef} onSubmit={this.onSubmit} method="post">
              <SearchSelect
                label="Selecione o professor para remover"
                searchUrl={`${Config.apiHost}mailing/search_professor/`}
                onChange={this.selectLead}
                value={this.state.selectedLead}
              />

              <Select
                required
                label="Plano"
                name="plan_id"
                options={this.state.plans.map((p) => ({
                  value: p.id,
                  label: p.name,
                }))}
                value={this.state.form.plan_id}
                onChange={this.onInputChange}
              />
              <Input
                required
                label="Quantidade"
                type="number"
                name="quantity"
                value={this.state.form.quantity}
                onChange={this.onInputChange}
              />
              <Textarea
                required
                label="Descrição"
                name="description"
                value={this.state.form.description}
                onChange={this.onInputChange}
              />
              <br />
              <Button submit>Confirmar</Button>
            </form>
          )}
        </Modal>
      </>
    );
  }
}

export default VoucherCreationButton;
