import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import FormGroup from './FormGroup';
import Ajax from '../../ajax';

class SearchSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      processing: false,
      options: [],
      selected: props.value,
    };
    this.start = this.start.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fetchOptions = debounce(this.fetchOptions.bind(this), 300);
    this.onSelect = this.onSelect.bind(this);
    this.queryInputRef = React.createRef();
  }

  start() {
    this.setState((state) => ({
      ...state,
      options: [],
      query: '',
    }));

    setTimeout(() => this.queryInputRef.current.focus(), 50);
  }

  fetchOptions() {
    if (!this.state.query.trim()) {
      this.setState((state) => ({
        ...state,
        options: [],
      }));
      return;
    }

    this.setState((state) => ({ ...state, processing: true }));
    const url = `${this.props.searchUrl}?q=${this.state.query.trim()}`;
    Ajax.get(url)
      .done((options) => {
        this.setState((state) => ({ ...state, options }));
      })
      .always(() => {
        this.setState((state) => ({ ...state, processing: false }));
      });
  }

  onChange(e) {
    const { name, value } = e.target;
    this.setState(
      (state) => ({
        ...state,
        [name]: value,
      }),
      this.fetchOptions
    );
  }

  onSelect(selected) {
    this.setState((state) => ({ ...state, selected }));
    this.props.onChange(selected);
  }

  render() {
    return (
      <FormGroup>
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-default dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={this.start}
          >
            <span>
              <span>
                {this.state.selected
                  ? this.state.selected[this.props.optionLabelKey]
                  : this.props.label}
              </span>
              <span className="caret" style={{ marginLeft: '30px' }} />
            </span>
          </button>
          {this.state.selected && (
            <button
              type="button"
              className="btn btn-default"
              onClick={() => this.onSelect(null)}
            >
              <span className="fas fa-times" />
            </button>
          )}
          <ul className="dropdown-menu">
            <li>
              <div className="input-group" style={{ padding: '4px 8px' }}>
                <input
                  placeholder="Search"
                  className="form-control"
                  name="query"
                  value={this.state.query}
                  onChange={this.onChange}
                  autoComplete="off"
                  ref={this.queryInputRef}
                />
                {this.state.processing && (
                  <div className="input-group-addon">
                    <i className="fas fa-spinner fa-pulse fa-1x fa-fw" />
                  </div>
                )}
              </div>
            </li>
            <li
              className="ff-scrollbar"
              style={{ maxHeight: '250px', overflowY: 'auto' }}
            >
              <ul>
                {this.state.options.map((opt, idx) => (
                  <li key={idx}>
                    <a role="button" onClick={() => this.onSelect(opt)}>
                      {opt[this.props.optionLabelKey]}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </div>
      </FormGroup>
    );
  }
}

SearchSelect.propTypes = {
  label: PropTypes.string,
  /**
   * An endpoint that return a list of objects with the properties label and
   * value.
   */
  searchUrl: PropTypes.string.isRequired,
  /**
   * A function that receives the selected object.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Initial selected value.
   */
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  /**
   * This is the key in the returned objects that will be used as the label
   * shown in the result list. Default is a property called 'label'.
   */
  optionLabelKey: PropTypes.string,
};

SearchSelect.defaultProps = {
  optionLabelKey: 'label',
};

export default SearchSelect;
