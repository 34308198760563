import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { AppContext, canCancelPlan, hasActivePlan } from '../context/global';
import Modal from '../common/containers/Modal';
import Videos from '../help/Videos';
import Button from '../common/components/Button';

class FreshDesk extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showPreSupportModal: false,
      showPreCancelModal: false,
    };

    this.openWidget = this.openWidget.bind(this);
    this.loadScript = this.loadScript.bind(this);
    this.openWidgetCancelamento = this.openWidgetCancelamento.bind(this);
    this.hideAndFillProperties = this.hideAndFillProperties.bind(this);
    this.getPriority = this.getPriority.bind(this);
    this.getDescription = this.getDescription.bind(this);
    this.getCommonFields = this.getCommonFields.bind(this);
  }

  componentDidMount() {
    this.hideAndFillProperties();
  }

  getDescription(begin = '') {
    let description = (begin || '') + '\n\n\n-------\nNão apague as informações abaixo\n';

    if (this.props.documentId) {
      description += `[document_id: ${this.props.documentId}]\n`;
    }

    if (this.context.current_plan_name) {
      description += `[plan_name: ${this.context.current_plan_name}]\n`;
    }

    description += `[cc: ${canCancelPlan() ? 'Sim' : 'Não'}]\n`;

    return description;
  }

  getPriority() {
    const { user } = this.context;
    const hoursSinceJoined = (Date.now() - new Date(user.date_joined).getTime()) / 1000 / 60 / 60;
    let priority = 1;

    if (canCancelPlan()) {
      priority = 4;
    } else if (hoursSinceJoined <= 10) {
      priority = 3;
    } else if (hoursSinceJoined <= 20) {
      priority = 2;
    }

    return priority;
  }

  getCommonFields(documentId) {
    return {
      priority: this.getPriority(),
      custom_fields: {
        document_id: documentId,
        plan_name: this.context.current_plan_name,
        cf_can_cancel: canCancelPlan() ? 'Sim' : 'Não',
      },
    };
  }

  openWidget() {
    this.setState((state) => ({ ...state, showPreSupportModal: false }));
    if (typeof FreshworksWidget === 'undefined') {
      this.loadScript(this.openWidget);
    } else {
      FreshworksWidget('clear', 'ticketForm');
      FreshworksWidget('prefill', 'ticketForm', {
        description: this.getDescription(),
        ...this.getCommonFields(this.props.documentId),
      });
      FreshworksWidget('disable', 'ticketForm', ['email']);
      FreshworksWidget('open');
    }
  }

  openWidgetCancelamento() {
    this.setState((state) => ({ ...state, showPreCancelModal: false }));
    if (typeof FreshworksWidget === 'undefined') {
      this.loadScript(this.openWidgetCancelamento);
    } else {
      FreshworksWidget('prefill', 'ticketForm', {
        subject: `Cancelamento (Plano ${this.context.current_plan_name})`,
        description: this.getDescription('Informe o motivo do cancelamento: '),
        ...this.getCommonFields('00001'),
      });
      FreshworksWidget('disable', 'ticketForm', ['email']);
      FreshworksWidget('open', 'ticketForm');
    }
  }

  loadScript(callback) {
    this.setState((state) => ({ ...state, showModal: true }));

    window.fwSettings = {
      widget_id: 16000000214,
    };
    !(function () {
      if ('function' != typeof window.FreshworksWidget) {
        var n = function () {
          n.q.push(arguments);
        };
        (n.q = []), (window.FreshworksWidget = n);
      }
    })();

    const src = 'https://widget.freshworks.com/widgets/16000000214.js';
    if (!document.querySelector(`[src="${src}"]`)) {
      const script = document.createElement('script');
      script.async = true;
      script.defer = true;
      script.src = src;
      script.onload = () => {
        this.hideAndFillProperties();
        if (callback) {
          callback();
        }
        setTimeout(() => this.setState((state) => ({ ...state, showModal: false })), 1000);
      };
      document.body.appendChild(script);
    }
  }

  hideAndFillProperties() {
    if (typeof FreshworksWidget === 'undefined') {
      return;
    }

    FreshworksWidget('hide', 'launcher');
    const { user } = this.context;

    FreshworksWidget('identify', 'ticketForm', {
      name: !!user && user.fullname ? user.fullname : '',
      email: !!user ? user.email : '',
    });

    FreshworksWidget('hide', 'ticketForm', [
      'priority',
      // 'custom_fields.document_id',
      'custom_fields.plan_name',
      'custom_fields.cf_can_cancel',
    ]);
  }

  render() {
    const { i18n, showIcon } = this.props;

    const horario = (
      <span style={{ paddingTop: '10px', paddingRight: '5px', fontSize: '10pt' }}>
        <b className="text-danger">Horário de atendimento do suporte:</b> segunda à sexta-feira das 08:00 às 17:00
        horas.
      </span>
    );

    return (
      <>
        {!this.props.showOnlyCancel && (
          <a role="button" onClick={() => this.setState((state) => ({ ...state, showPreSupportModal: true }))}>
            {showIcon && <i className="mdi mdi-help" />} {this.props.label || i18n.t('Contato/Suporte')}
          </a>
        )}

        {this.props.showOnlyCancel && canCancelPlan() && (
          <a role="button" onClick={() => this.setState((state) => ({ ...state, showPreCancelModal: true }))}>
            <span className="btn btn-default btn-sm">{i18n.t('Solicitar cancelamento de assinatura')}</span>
          </a>
        )}

        {this.state.showModal && <Modal title="Carregando suporte..." isProcessing={true} show={true} />}

        {this.state.showPreSupportModal && (
          <Modal
            title="Antes de prosseguir para o suporte..."
            isProcessing={false}
            show={true}
            customWidth="1000px"
            onCancel={() => this.setState((state) => ({ ...state, showPreSupportModal: false }))}
            footer={
              <>
                {horario}
                <Button
                  type="default"
                  size="lg"
                  onClick={() => this.setState((state) => ({ ...state, showPreSupportModal: false }))}
                >
                  Fechar
                </Button>
                <Button type="primary" size="lg" onClick={this.openWidget}>
                  Prosseguir para o suporte
                </Button>
              </>
            }
          >
            <h4>
              Você já conferiu nossos vídeos tutorias que ajudam bastante os usuários iniciantes no FastFormat? Veja a
              seguir.
            </h4>

            <Videos />
          </Modal>
        )}

        {this.state.showPreCancelModal && (
          <Modal
            title="Antes de você partir..."
            isProcessing={false}
            show={true}
            customWidth="1000px"
            footer={
              <>
                {horario}
                <Button
                  type="default"
                  size="lg"
                  onClick={() => this.setState((state) => ({ ...state, showPreCancelModal: false }))}
                >
                  Fechar
                </Button>
                <Button type="default" size="lg" onClick={this.openWidgetCancelamento}>
                  Prosseguir com a solicitação de cancelamento
                </Button>
              </>
            }
          >
            <h4>
              ...gostaríamos de dizer que lamentamos muito que você não esteja tendo uma boa experiência com o
              FastFormat. &#x1F614;
            </h4>

            <h4>Mas estamos sempre trabalhando para melhorar nossa plataforma. &#x1F913;</h4>

            <h4>
              Que tal nos dar outra chance?! Já conferiu os vídeos tutorias que ajudam bastante os usuários iniciantes
              no FastFormat?
            </h4>

            <Videos />
          </Modal>
        )}
      </>
    );
  }
}

FreshDesk.propTypes = {
  documentId: PropTypes.number,
  showIcon: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  showOnlyCancel: PropTypes.bool,
};

FreshDesk.defaultProps = {
  showIcon: true,
};

FreshDesk.contextType = AppContext;

export default withNamespaces()(FreshDesk);
