import React from "react";
import Config from "../../config";
import Ajax from "../../common/ajax";
import Waiting from "../../common/containers/Waiting";
import PaginationWithClick from "../../common/components/PaginationWithClick";
import LocaleUtils from "../../common/LocaleUtils";
import Modal from "../../common/containers/Modal";
import * as validation from "../../common/validation";
import {AlertError, AlertSuccess} from "../../common/components/Alert";
import Button from "../../common/components/Button";
import Row from "../../common/containers/Row";
import Col from "../../common/containers/Col";
import Input from "../../common/components/form/Input";
import Panel from "../../common/containers/Panel";
import {withNamespaces} from "react-i18next";
import VoucherCreationButton from "./VoucherCreationButton";

class VouchersSimpleForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUrl: null,
            pagination: null,
            vouchers: [],
            processing: false,
            showModalRedeem: false,
            successMessage: null,
            errorMessage: null,
            processingModal: false,
            form: {},
        };
        this.fetchVouchers = this.fetchVouchers.bind(this);
        this.openModalRedeem = this.openModalRedeem.bind(this);
        this.closeModalRedeem = this.closeModalRedeem.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this._submitButtonRef = React.createRef();
    }

    componentDidMount() {
        this.fetchVouchers();
    }

    fetchVouchers(url = `${Config.apiHost}vouchers/`) {
        this.setState(state => ({
            ...state,
            processing: true,
        }));
        Ajax.get(url).done(pagination => {
            this.setState(state => ({
                ...state,
                processing: false,
                pagination,
                vouchers: pagination.results,
                currentUrl: url,
            }));
        });
    }

    openModalRedeem() {
        this.setState(state => ({
            ...state,
            showModalRedeem: true,
            errorMessage: null,
            successMessage: null,
            form: {},
        }));
    }

    closeModalRedeem() {
        if (this.state.successMessage) {
            this.setState(state => ({
                ...state,
                processingModal: true,
            }), () => {
                location.reload();
            });
        } else {
            this.setState(state => ({
                ...state,
                showModalRedeem: false,
            }));
        }
    }

    onInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(state => ({
            ...state,
            form: {
                ...state.form,
                [name]: value
            }
        }));
    }

    onSubmit(evt) {
        evt.preventDefault();

        $('p.has-error').remove();
        $('.has-error').removeClass('has-error');

        this.setState(state => ({
            ...state,
            processingModal: true,
            successMessage: null,
            errorMessage: null,
        }));

        const url = `${Config.apiHost}vouchers/redeem_simple_form/`;

        Ajax.post(url, this.state.form).done(response => {
            this.setState(state => ({
                ...state,
                processingModal: false,
                successMessage: 'Resgate realizado com sucesso',
            }));
            this.fetchVouchers();
        }).fail(response => {
            this.setState(state => ({
                ...state,
                processingModal: false,
                errorMessage: 'Não foi possível fazer o resgate do seu cupom.',
            }));
            validation.showDjangoValidationMessages(response.responseJSON);
        });
    }

    renderCheckoutRows() {
        const {i18n} = this.props;
        if (this.state.vouchers.length > 0) {
            return this.state.vouchers.map(redeem => {
                return (
                    <tr key={redeem.id}>
                        <td>{redeem.plan_name}</td>
                        <td style={{textDecoration: redeem.redeem_date ? 'line-through' : ''}}>{redeem.private_code}</td>
                        <td>{redeem.redeem_date && LocaleUtils.fromNow(redeem.redeem_date) || '-'}</td>
                        <td>{redeem.user ? redeem.user.email : '-'}</td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="4">{i18n.t('Nenhum resgate encontrado')}</td>
                </tr>
            );
        }
    }

    renderModalRedeem() {
        const {i18n} = this.props;
        let body;

        if (this.state.successMessage) {
            body = <AlertSuccess>{this.state.successMessage}</AlertSuccess>;
        } else {
            body = (
                <>
                    <AlertError>{this.state.errorMessage}</AlertError>
                    <Row>
                        <Col md={5}>
                            <Input
                                name="private_code"
                                id="id_private_code"
                                label={i18n.t('Código do voucher')}
                                required={true}
                                value={this.state.form.private_code || ''}
                                onChange={this.onInputChange}
                                maxLength="8"
                                data-lpignore="true"
                            />
                        </Col>
                    </Row>
                </>
            );
        }

        const footer = (
            <React.Fragment>
                <Button type="default"
                        size="lg"
                        onClick={this.closeModalRedeem}>
                    {i18n.t('Fechar')}
                </Button>

                {!this.state.successMessage &&
                <Button type="primary"
                        size="lg"
                        onClick={() => this._submitButtonRef.current.click()}
                        disabled={this.state.processingModal}>
                    {i18n.t('Resgatar')}
                </Button>}
            </React.Fragment>
        );

        return (
            <Modal title={i18n.t('Resgatar') + ' voucher'}
                   show={this.state.showModalRedeem}
                   onCancel={this.closeModalRedeem}
                   footer={footer}>
                <form onSubmit={this.onSubmit}>
                    <Waiting isProcessing={this.state.processingModal}>
                        {body}
                    </Waiting>
                    <button
                        ref={this._submitButtonRef}
                        type="submit"
                        style={{display: 'none'}}
                    />
                </form>
            </Modal>
        );
    }

    render() {
        const {i18n} = this.props;
        const tools = (
            <>
                <VoucherCreationButton/>
                <Button type="primary" onClick={this.openModalRedeem}>
                    {i18n.t('Resgatar')}
                </Button>
            </>
        );

        return (
            <React.Fragment>
                <Panel isPanelTable={true}
                       title={i18n.t('Vouchers')}
                       tools={tools}
                       isProcessing={this.state.processing}>
                    <table className="table table-condensed table-striped">
                        <thead>
                        <tr>
                            <th>{i18n.t('Plano')}</th>
                            <th>{i18n.t('Código para resgate')}</th>
                            <th>{i18n.t('Data resgate')}</th>
                            <th>{i18n.t('Resgatado por')}</th>
                        </tr>
                        </thead>
                        <tbody>
                            {this.renderCheckoutRows()}
                        </tbody>
                    </table>
                </Panel>

                {this.state.pagination && (
                    <PaginationWithClick
                        handleClick={this.fetchVouchers}
                        pagination={this.state.pagination}
                    />
                )}

                {this.renderModalRedeem()}
            </React.Fragment>
        );
    }
}

export default withNamespaces()(VouchersSimpleForm);