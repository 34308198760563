import React from 'react';
import PrivatePage from '../../common/containers/PrivatePage';
import Content from '../../common/containers/Content';
import Payments from './Payments';
import Vouchers from './VouchersSimpleForm';
import CurrentPlan from './CurrentPlan';
import Row from '../../common/containers/Row';
import Col from '../../common/containers/Col';
import ErrorBoundary from '../../common/containers/ErrorBoundary';
import { withNamespaces } from 'react-i18next';

class BillingPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { i18n } = this.props;
    return (
      <PrivatePage title={i18n.t('Pagamentos e vouchers')}>
        <Content>
          <Row>
            <Col md={12}>
              <ErrorBoundary>
                <CurrentPlan />
              </ErrorBoundary>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <ErrorBoundary>
                <Payments />
              </ErrorBoundary>
            </Col>
            <Col md={6}>
              <ErrorBoundary>
                <Vouchers />
              </ErrorBoundary>
            </Col>
          </Row>
        </Content>
      </PrivatePage>
    );
  }
}

export default withNamespaces()(BillingPage);
