import React from 'react';
import Config from '../../config';
import Ajax from '../../common/ajax';
import PaginationWithClick from '../../common/components/PaginationWithClick';
import LocaleUtils from '../../common/LocaleUtils';
import Panel from '../../common/containers/Panel';
import { withNamespaces } from 'react-i18next';
import Modal from '../../common/containers/Modal';
import MercadoPagoStatusV2 from '../payment/MercadoPagoStatusV2';

class Payments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: null,
      checkouts: [],
      processingCheckouts: false,
      mercadoPagoCheckout: null,
      mercadoPagoPublicKey: null,
    };
    this.fetchCheckouts = this.fetchCheckouts.bind(this);
  }

  componentDidMount() {
    this.fetchCheckouts();
  }

  fetchCheckouts(url = `${Config.apiHost}checkouts/`) {
    this.setState((state) => ({
      ...state,
      processingCheckouts: true,
    }));

    Ajax.get(url).done((pagination) => {
      this.setState((state) => ({
        ...state,
        processingCheckouts: false,
        pagination,
        checkouts: pagination.results,
      }));
    });
  }

  renderCheckoutRows() {
    const { i18n } = this.props;

    const isValidMercadoPago = (checkout) => checkout.vendor === 'mercadopago' && !!checkout.payment_code;

    if (this.state.checkouts.length > 0) {
      return this.state.checkouts.map((checkout) => {
        return (
          <tr
            key={checkout.id}
            role={isValidMercadoPago(checkout) ? 'button' : ''}
            onClick={
              isValidMercadoPago(checkout)
                ? () => this.setState((state) => ({ ...state, mercadoPagoCheckout: checkout }))
                : null
            }
          >
            <td>{checkout.plan_type_name}</td>
            <td>{checkout.plan_name}</td>
            <td>{checkout.voucher_amount ? checkout.voucher_amount : 1}</td>
            <td>R$ {checkout.total}</td>
            <td>{LocaleUtils.fromNow(checkout.creation_date)}</td>
            <td>
              {checkout.type_name}
              &nbsp;
              {checkout.type === 'bank_slip' && checkout.payment_status === 1 && (
                <a href={checkout.payment_url} target="_blank" className="btn btn-primary btn-xs">
                  {i18n.t('Imprimir')}
                </a>
              )}
            </td>
            <td>
              {checkout.status_name}
              {isValidMercadoPago(checkout) && (
                <a role="button">
                  {' '}
                  <i className="mdi mdi-open-in-new" />
                </a>
              )}
            </td>
          </tr>
        );
      });
    }
  }

  render() {
    const { i18n } = this.props;
    return (
      <React.Fragment>
        <Panel title={i18n.t('Meus pagamentos')} isPanelTable={true} isProcessing={this.state.processingCheckouts}>
          <table className="table table-condensed table-striped">
            <thead>
              <tr>
                <th>{i18n.t('Tipo')}</th>
                <th>{i18n.t('Plano')}</th>
                <th>{i18n.t('Quantidade')}</th>
                <th>{i18n.t('Valor')}</th>
                <th>{i18n.t('Data')}</th>
                <th>{i18n.t('Pagamento')}</th>
                <th>{i18n.t('Situação')}</th>
              </tr>
            </thead>
            <tbody>{this.renderCheckoutRows()}</tbody>
          </table>
        </Panel>
        {this.state.pagination && (
          <PaginationWithClick handleClick={this.fetchCheckouts} pagination={this.state.pagination} />
        )}

        {this.state.mercadoPagoCheckout && (
          <Modal
            title={i18n.t('Detalhe do pagamento') + ` (${this.state.mercadoPagoCheckout.plan_name})`}
            show={true}
            onCancel={() => this.setState((state) => ({ ...state, mercadoPagoCheckout: null }))}
            width="large"
            bodyMinHeight="400px"
          >
            <MercadoPagoStatusV2 checkout={this.state.mercadoPagoCheckout} />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default withNamespaces()(Payments);
